<template>
    <div class="popup">
        <div class="box">
            <div class="popTitle popTitleArea">
                <p>{{isCancel? $t('page.transfer.popup.title.cancel'):$t('page.transfer.popup.title.confirm')}}</p>
                <div class="buttonArea">
                    <button class="x" @click="closePopup(false)"><i class="micon">close</i></button>
                </div>
            </div>
            <div v-if="!isCancel" class="popupContentArea">
                <ul class="cont">
                    <li>
                        <div class="titArea">
                            <p class="tit">{{ $t('page.transfer.popup.subTitle.view') }}</p>
                        </div>
                        <ul class="contArea">
                            <li>
                                <p>{{ $t('page.transfer.reqUser') }}</p>
                                <span>{{ result.requestNm }} ({{ result.requestId }})</span>
                            </li>
                            <li>
                                <p>{{ $t('page.transfer.popup.text.patient') }}</p>
                                <span>{{ result.patientNm }} ({{ result.patientSn }})</span>
                            </li>
                            <li>
                                <p>{{ $t('page.transfer.transfee') }}</p>
                                <span>{{ result.transfereeNm }} ({{ result.transfereeId }})</span>
                            </li>
                            <li>
                                <p>{{ $t('page.transfer.reqDt') }}</p>
                                <span>{{ result.crtDtStr }}</span>
                            </li>
                            <li>
                                <p>{{ $t('page.transfer.popup.subTitle.reqNote') }}</p>
                                <span style="text-align: left !important; min-height:100px; overflow-y: auto; line-height: 2rem;">{{ result.comment }}</span>
                            </li>
                        </ul>
                    </li>
                </ul>
                <div class="btnW popBtn">
                    <button class="btn default" @click="closePopup(false)" v-ripple>{{ $t('common.button.ok') }}</button>
                    <button class="btn line danger" @click="isCancel = true" v-ripple>{{ $t('page.transfer.popup.button.cancel') }}</button>
                </div>
            </div>
            <div v-else class="popupContentArea cont">
                <div class="titArea">
                    <p class="tit">{{ $t('page.transfer.popup.subTitle.cancelNote') }} *</p>
                </div>
                <div class="mb20">
                    <v-textarea flat solo density="compact" hide-details="auto" v-model="comment"></v-textarea>
                </div>
                <div class="btnW popBtn">
                    <button class="btn line danger" @click="cancelRequest()" v-ripple>{{ $t('page.transfer.popup.button.cancel') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import API_SHOW_REQUEST from '@/API/transferRequest/detail'
import API_CANCEL_REQUEST from '@/API/transferRequest/cancelRequest'
export default {
    components: {
    },
    props: {
        requestNo: {
            default: null
        }
    },
    data() {
        return {
            isGetingItems: false,
            requestComment: '',
            result: {},
            isCancel: false,
            comment: ''
        };
    },
    watch: {},
    created() {},
    mounted() {
        this.getData();
    },
    destroyed() {},
    methods: {
        closePopup(progress) {
            this.$emit('closePopup', progress);
            if(this.isCancel) this.isCancel = false
        },
        async getData() {
            if (this.isGetingItems) {
                return;
            }
            this.isGetingItems = true;
            let res = await API_SHOW_REQUEST.request(this.requestNo);
            if (res.isSuccess) {
                this.result = res.data;
            } else {
                this.showPopup(res.errorMsg, res.status);
            }
            this.isGetingItems = false;
        },
        async cancelRequest(){
            if(this.comment === '') {
                this.showPopup(this.$t('page.transfer.popup.msg.fillNote'))
                return;
            }
            if (this.isGetingItems) {
                return;
            }
            this.isGetingItems = true;
            let res = await API_CANCEL_REQUEST.request(this.result.no, 'CANCL', this.comment);
            if (res.isSuccess) {
                this.showPopup(this.$t('page.transfer.popup.msg.cancelSuccess'));
                this.closePopup(false)
                this.$emit('checkRequestNo', null);
            } else {
                this.showPopup(res.errorMsg, res.status);
            }
            this.isGetingItems = false;
        }
    },
    computed:{},
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";
.popup {
    .box{
        max-height: 95vh;
        .x{position: static;}
        .popTitle{
            display:flex; justify-content: space-between; align-items: center;
            p{
                display:flex; justify-content: flex-start; align-items: center;
                i{margin-right: 8px;}
            }
        }
        .cont{
            >li{
                margin-bottom: 25px;
                &:last-child{margin-bottom: 0;}
                .titArea{
                    display: flex; justify-content: space-between; align-items: center; margin-bottom: 8px;
                    .tit{margin-bottom: 0;}
                }
                ul.contArea{
                    padding-left: 0;
                    li{
                        display: flex; align-items: center; padding: 0 0 4px 8px; font-size: 14px;
                        p{width: 30%;}
                        span{width: 70%; padding: 0 10px; background: #fbfbfb; border: 1px solid #e5e5e5; border-radius: 4px; height: 30px; line-height: 30px; color: #111 !important; text-align: center;}
                    }
                }
                .compInfo{
                    font-size: 14px; padding:20px 10px; background: #fbfbfb; border: 1px solid #e5e5e5; border-radius: 4px; display: flex; flex-direction: column; flex-wrap: nowrap; justify-content: flex-start; align-items: center;
                    img{margin: 0 auto 10px auto;}
                    a{color: inherit;}
                    ul{
                        display:flex; justify-content: space-between; align-items: center; gap: 8px; margin-bottom: 10px;
                        li{
                            &::after{content:'|'; padding-left: 4px;}
                            &:last-child::after{content:''; padding-left: 0;}
                            span{padding-right: 4px; font-weight: bold;}
                        }
                    }
                }
                &.copyright{font-size: 14px; text-align:center; margin-bottom: 0; color:#777;}
            }
            
        }
    }
}
.borderWrap{border: 1px solid #dbdbdb;}
.tblScroll{
    max-height: 250px;
    border-radius: 4px;
    .tbl{
        tr:hover{cursor: pointer;}
        border-top:0;
        border-bottom: 0;
        thead th{font-size: 14px; padding: 12px;}
        td{font-size: 14px; padding: 12px; height: auto;}
    }
}
::v-deep .lay-root.thema-default{
    background-color: var(--color-point) !important;
    transition:box-shadow 0.5s,opacity 0.5s;
    &:hover{
        opacity: 0.85; box-shadow:0 4px 7px rgba(0,0,0,0.1);
    }
}

::v-deep .v-input__slot .v-text-field__slot textarea{padding: 10px !important;}
.infoBox{margin-bottom:20px;}
.infoBox ul{padding: 10px; background: #fbfbfb; border: 1px solid #e5e5e5; border-radius: 4px; margin-bottom: 20px;}
.infoBox ul li{color:#111; padding: 0 0 4px 8px; font-size: 14px;}
.infoBox p{text-align: center; font-size: 15px; line-height: 2rem; font-weight: bold;}

</style>
